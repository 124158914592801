import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const LogList = lazy(() => import('../../container/log/logList'));

const Logs = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<LogList />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default Logs;
