import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const ClientList = lazy(() => import('../../container/client/clientList'));
const ClientAdd = lazy(() => import('../../container/client/clientAdd'));
const ClientEdit = lazy(() => import('../../container/client/clientEdit'));

const Client = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<ClientList />} />
        <Route path="/add" element={<ClientAdd />} />
        <Route path="edit/:id" element={<ClientEdit />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default Client;
