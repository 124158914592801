import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const TrialList = lazy(() => import('../../container/trial/trialList'));
const TrialAdd = lazy(() => import('../../container/trial/trialAdd'));
const TrialEdit = lazy(() => import('../../container/trial/trialEdit'));
const TrialCalendar = lazy(() => import('../../container/trial/trialCalendar'));

const Trial = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/" element={<TrialList />} />
        <Route index path="/add" element={<TrialAdd />} />
        <Route index path="/calendar/:id/*" element={<TrialCalendar />} />
        <Route index path="/edit/:id/*" element={<TrialEdit />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default Trial;
