import { useEffect, useState } from 'react';

const getIP = () => {
  const [ipAddress, setIPAddress] = useState('');

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip));
  }, []);

  return ipAddress;
};

export default getIP;
