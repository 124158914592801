import { useState, useEffect } from 'react';
import getIP from './getIP';

const iPWhitelist = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const ipAddress = getIP();

  useEffect(() => {
    const checkIPWhitelist = () => {
      const allowedIPs = process.env.REACT_APP_ALLOWED_IPS.split(',');
      const environment = process.env.REACT_APP_ENVIRONMENT;
      if (environment === 'staging') {
        setIsAllowed(true);
      } else if (ipAddress && allowedIPs.includes(ipAddress)) {
        setIsAllowed(true);
      } else {
        setIsAllowed(false);
      }
    };

    checkIPWhitelist();
  });

  return isAllowed;
};

export default iPWhitelist;
